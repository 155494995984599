<template>
  <div>
    <v-layout wrap>
      <v-flex text-start>
        <span style="font-size: 20px; font-family: poppinsbold"
          >Room Availability</span
        >
      </v-flex>
      <v-flex lg12 xl12 pt-3>
        <v-snackbar
          v-model="showSnackBar"
          color="#68D389"
          right
          :timeout="timeout"
        >
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: #ffffff">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: #ffffff">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>

        <v-layout wrap justify-center>
          <v-flex xs12 text-left>
            <span style="font-family: poppinssemibold; font-size: 16px">
              Please Select the Range for Room Availability
              <sup>*</sup>
            </span>
          </v-flex>
          <v-flex xs12 pl-3 pt-7 pb-2>
            <v-layout wrap>
              <v-flex xs12 sm6 md4>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="slots.fromDate"
                      label="Please Select the Start Date"
                      outlined
                      dense
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="slots.fromDate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm6 md4 offset-md1 offsetlg offset-xl1>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="slots.toDate"
                      label="Please Select the End Date"
                      dense
                      outlined
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="slots.toDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex text-left>
            <span style="font-family: poppinssemibold; font-size: 16px">
              Please select the room type
              <sup>*</sup>
            </span>
          </v-flex>
          <v-flex xs12 md12 pt-4 pb-2 pl-3>
            <v-layout wrap justify-start fill-height>
              <v-flex
                md2
                v-for="(item, index) in slotTypes"
                :key="index"
                align-self-center
              >
                <v-checkbox
                  class="textField2"
                  dense
                  v-model="slots.slotType"
                  outlined
                  :label="item.title"
                  :value="item.value"
                  hide-details
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex md2 text-left align-self-center pl-4 pt-3>
            <v-text-field
              v-if="slots.slotType === 'offset'"
              class="tableValue"
              v-model="slots.offset"
              required
              type="number"
              dense
              outlined
              placeholder="Offset No."
              hide-details
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-center
          pt-4
          pb-2
          v-if="slots.slotType == 'daywise'"
        >
          <v-flex xs12>
            <v-chip-group
              mandatory
              style="text-align: center"
              active-class="primary--text"
              multiple
              v-model="slots.isDaywise"
            >
              <v-chip v-for="tag in items" :key="tag.text">
                {{ tag.text }}
              </v-chip>
            </v-chip-group>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pt-4>
          <v-flex xs12>
            <v-layout justify-start pb-3>
              <v-flex xs2 md3 text-left>
                <span style="font-size: 16px; font-family: poppinssemibold"
                  >Available Timings</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap justify-left pt-2 pl-3>
              <v-flex md3 text-left>
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinsreglar;
                    color: #9e9e9e;
                  "
                  >Checkin Time <sup>* </sup></span
                >
                <br />

                <input
                  type="time"
                  class="px-15"
                  v-model="slots.slotDetails[0].startTime"
                  placeholder="ii ijiihd oudwhiuh"
                  label="hii"
                  style="
                    border: 0.5px solid #c8c8c8;
                    padding-top: 7.5px;
                    padding-bottom: 5px;
                  "
                  required
                />
              </v-flex>
              <v-flex md3 text-left>
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinsreglar;
                    color: #9e9e9e;
                  "
                  >Checkout Time <sup>*</sup></span
                >
                <br />
                <input
                  type="time"
                  class="px-15"
                  v-model="slots.slotDetails[0].endTime"
                  placeholder="ii ijiihd oudwhiuh"
                  style="
                    border: 0.5px solid #c8c8c8;
                    padding-top: 7.5px;
                    padding-bottom: 5px;
                  "
                  required
                />
              </v-flex>
              <v-flex md4 text-left>
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinsreglar;
                    color: #9e9e9e;
                  "
                  >Please Mention Total Rooms Available <sup>*</sup></span
                >
                <br />
                <v-text-field
                  class="tableValue"
                  v-model="slots.slotDetails[0].availableNo"
                  required
                  type="number"
                  style="width: 90% !important"
                  placeholder="Total Rooms"
                  dense
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-end py-4>
          <v-flex md4 xs12>
            <v-btn
              block
              tile
              color="#68D389"
              light
              :ripple="false"
              depressed
              @click="checkSlotDetails"
              class="itemValue"
            >
              <span style="color: #ffffff">{{ pageType }} Slot</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["programData", "slotData", "pageType"],
  data() {
    return {
      test: null,
      menu: false,
      modal: false,
      menu2: false,
      dialog: false,
      isProgramAdded: true,
      slots: {
        programme: null,
        fromDate: new Date().toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10),
        slotType: "daily",
        slotDetails: [{ startTime: null, endTime: null, availableNo: null }],
        isDaywise: [],
        offsetNo: null,
      },
      slotTypes: [
        {
          title: "Daily",
          value: "daily",
        },
        {
          title: "DayWise",
          value: "daywise",
        },

        {
          title: "Offset",
          value: "offset",
        },
      ],
      items: [
        {
          text: "Sunday",
          value: 0,
        },
        {
          text: "Monday",
          value: 1,
        },
        {
          text: "Tuesday",
          value: 2,
        },
        {
          text: "Wednesday",
          value: 3,
        },
        {
          text: "Thursday",
          value: 4,
        },
        {
          text: "Friday",
          value: 5,
        },
        {
          text: "Saturday",
          value: 6,
        },
      ],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    };
  },
  beforeMount() {
    if (this.pageType == "Edit") this.slots = this.slotData;
  },
  methods: {
    checkSlotDetails() {
      var isOkay = false;
      if (
        this.slots.slotDetails[0].startTime &&
        this.slots.slotDetails[0].endTime &&
        this.slots.slotDetails[0].availableNo
      )
        isOkay = true;
      if (!isOkay) {
        this.showSnackBar = true;
        this.msg = "Please enter all slot details";
      } else this.addSlots();
    },
    validateInput() {
      this.addSlots();
    },
    addSlots() {
      if (!this.slots.slotType) {
        this.showSnackBar = true;
        this.msg = "Please Enter Slot Type";
      }
      this.$emit("stepper", {
        type: this.pageType,
        slotData: this.slots,
      });
    },
    formatTime(item) {
      if (!item) return;
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
  },
};
</script>
<style>
.v-application .primary--text {
  color: #68d389 !important;
  caret-color: #68d389 !important;
}
.v-application .accent {
  background-color: #68d389 !important;
  border-color: #68d389 !important;
}
.theme--light.v-chip:not(.v-chip--active) {
  background-color: #ebe6e6 !important;
}
.v-application .primary {
  background-color: #68d389 !important;
}
</style>  